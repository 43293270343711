<template>
  <div>
    <v-header deep theme/>
    <div class="container">
      <img class="logo" src="@/assets/images/white-logo.png" alt="">
      <div class="slogan">让超级个体，先快速找到好资料</div>

      <template v-if="!code">
        <template v-if="model">
          <img class="avatar" :src="model.avatar" alt="">
          <div class="confirm-title">点击确认绑定微信至用户
            <div class="nickName">{{ model.username }}</div>
          </div>
          <div class="confirm-btn" @click="confirm">确认绑定</div>
        </template>
        <template v-else>
          <div class="confirm-title" style="padding-top: 200px">绑定信息不存在，请重新扫码</div>
        </template>
      </template>
      <template v-if="code">
        <van-loading color="#999" type="spinner" style="margin: 120px 0 30px 0"/>
        <div class="confirm-title">绑定中</div>
      </template>

    </div>
  </div>
</template>

<script>

import {updateBindWxV2Api} from "../../api/v2/updateBindWxV2Api";

export default {

  mounted() {
    const {code, uuid} = this.$route.query
    if (code) {
      this.updateBindWx(code, uuid)
    } else {
      this.loadBindInfo(uuid);
    }
  },


  data() {
    return {
      model: {
        id: '',
        uuid: '',
        hasUse: '',
        expireTime: '',
        username: '',
        avatar: ''
      },
      code: ''
    }
  },


  methods: {

    // 加载绑定的信息
    async loadBindInfo(uuid) {
      const res = await updateBindWxV2Api.findByUuid(uuid)
      if (!res) {
        this.$toast.info('绑定信息不存在，请重新扫码')
      }
      this.model = res
    },


    // 确认绑定
    async confirm() {
      if (!this.model) {
        return this.$toast.info('绑定信息不存在')
      }
      const hasExpire = new Date(this.model.expireTime.replace(/-/g, '/')).getTime() < Date.now()
      if (this.model.hasUse || hasExpire) {
        return this.$toast.info('二维码已过期，请重新扫码')
      }
      const redirectUrl = window.location.href
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?response_type=code&scope=snsapi_userinfo&state=STATE&wechart_redirect&appid=' + this.appId + '&redirect_uri=' + redirectUrl;
    },


    // 更新绑定
    async updateBindWx(code, uuid) {
      try {
        this.code = code;
        await updateBindWxV2Api.updateBindWx(code, uuid)
        this.$toast.success('操作成功')
        await this.$router.push({
          path: '/wx',
          query: {
            redirect: 'https://m.001ppt.com'
          }
        })
      } catch (e) {
        this.$toast.info(e.message)
        await this.loadBindInfo(uuid)
        this.code = ''
      }
    }

  }

}
</script>

<style scoped lang="less">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #151D36;
  padding-top: 120px;
}

.logo {
  width: calc(50vw);
}

.slogan {
  margin-top: 16px;
  color: #fff;
  font-size: 12px;
}

.avatar {
  height: 100px;
  width: 100px;
  border-radius: 1000px;
  margin: 120px 0;
}

.confirm-title {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 12px;
}

.nickName {
  color: #fff;
  //font-weight: bolder;
  padding-left: 4px;
}

.confirm-btn {
  background: #fff;
  color: #151D36;
  width: calc(100vw - 64px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-top: 16px;
  font-weight: bolder;
}
</style>
