import {v2Request} from "./v2Request";


export const updateBindWxV2Api = {

  // 根据uuid查询
  findByUuid(uuid) {
    return v2Request.get('updateBindWx/findByUuid', {params: {uuid}})
  },


  // 更新绑定
  updateBindWx(code, uuid) {
    return v2Request.post('updateBindWx/updateBindWx', {code, uuid})
  }

}
